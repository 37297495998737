<template>
	<div class="myheader">
		<div style="display: flex;align-items: center;height: 100%;">
			<img src="../assets/logo.png" alt="" style="width: 40px;height: 40px;">
			<span style="height: 45%;width: 1px;background-color: #c0c0c0;margin: 0 0.3rem;"></span>
			<span style="font-size: 0.2rem;color: #333;">西宁市城北区人民检察院公文管理系统</span>
			<!-- <span style="font-size: 0.2rem;color: rgb(64,158,255);cursor: pointer;margin-left: 20px;"
				@click="gogfwz">官方网站</span> -->
		</div>

		<div class="info">
			<el-dropdown trigger="click" @command="handleCommand">
				<span class="el-dropdown-link">
					<div style="display: flex;align-items: center;cursor: pointer;">
						<div
							style="height: 35px;width: 35px;border-radius: 100%;background-color: rgb(64,158,255);display: flex;align-items: center;justify-content: center;font-size: 18px;color: #fff;margin-right: 10px;">
							{{$store.state.userinfo.username?$store.state.userinfo.username.charAt(0):''}}
						</div>
						<span style="color: #333;">{{$store.state.userinfo.username}}</span>
						<i class="el-icon-arrow-down el-icon--right" style="font-size: 0.2rem;"></i>
					</div>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item icon="el-icon-setting" command="info">个人设置</el-dropdown-item>
					<el-dropdown-item icon="el-icon-switch-button" command="out">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			
			<edituser ref="myinfo"></edituser>
		</div>
		
		
	</div>
</template>

<script>
	import edituser from './edit_myinfo/edit_myinfo.vue'
	export default {
		components:{edituser},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {
			gogfwz() {
				this.$router.push('/')
			},
			handleCommand(command) {
				if (command == 'out') {
					this.$store.commit('clearuserinfo')
					localStorage.clear()
					this.$router.replace('/login').catch(err => {
						console.log(err)
					})
				}
				if (command == 'info') {
					this.$post({
					    url: '/api/User/dqdetails',
					    params: {
					        
					    }
					}).then((res) => {
						this.$refs.myinfo.ruleForm = res
						this.$refs.myinfo.ruleForm.sex = Number(res.sex)
						this.$refs.myinfo.dialogVisible = true
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.myheader {
		background-color: #fff;
		width: 100%;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		padding: 0 20px;
		box-shadow: 0 0 10px #ddd;

		.info {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 35px;
			height: 100%;
			cursor: pointer;

			img {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				margin-right: 10px;
			}
		}
	}

	.loginout {
		display: flex;
		align-items: center;
		margin-left: 10px;
		cursor: pointer;
		padding: 0 8px;
	}

	.loginout:hover {
		background-color: rgba(255, 255, 255, 0.3);
		height: 100%;
	}
</style>